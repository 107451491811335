import React from 'react';
import { Link } from 'gatsby';

import Layout from '../components/layout';
import SEO from '../components/seo';

import gif from '../assets/images/head_construction.gif';

const NotFoundPage = () => {
  return (
    <Layout>
      <SEO title="404: Not found" />
      <div className="wrapper">
        <h1>NOT FOUND</h1>
        <img src={gif} alt="" />
        <p>
          <strong>
            <em>
              Please contact the <Link to="/404">WebMaster</Link>
            </em>
          </strong>
        </p>
      </div>
    </Layout>
  );
};

export default NotFoundPage;
